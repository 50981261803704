<template>
  <div class="main p-b-20">
    <div class="w1200">
      <div class="padc16 font14 color999 flex-left">
        <img src="@/assets/images/icon_27.png" alt="" />
        <span class="m-l-8">当前位置</span>
        <span class="pad8">/</span>
        医知学
        <span class="pad8">/</span>
        <span class="colorff9">分类</span>
      </div>
      <div class="flex-left pad30 m-head">
        <div class="font14 color333 weight m-r-30">医用分类：</div>
        <div class="flex-left font14 color999 flex-1 mh-con">
          <div
            v-for="(item, inx) in courseList"
            :key="inx"
            class="flex-left pad16 padc6 hand"
            :class="curMenu == inx ? 'mhc-ac' : ''"
            @click="curMenus(item.typId, (curMenu = inx))"
          >
            <span>{{ item.typeName }}</span>
            <img
              v-if="curMenu == inx"
              class="m-l-8"
              src="@/assets/images/icon_26.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="m-t-20 flex-between font14 color999 p-l-20 p-r-30 m-top">
        <div class="flex-left">
          <div
            class="padc4 pad10 hand m-r-90"
            :class="curFil == inx ? 'color333 weight font16' : ''"
            @click="curFils((curFil = inx))"
            v-for="(item, inx) in filList"
            :key="inx"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="flex-left">
          共
          <span class="pad4 colorff9 weight">{{ total }}</span>
          个课程，每页显示{{ per_page }}个课程
        </div>
      </div>
      <div class="m-t-28 m-con">
        <div class="flex-wrap">
          <div
            class="mc-li m-r-20 m-b-20"
            v-for="(item, index) in menuNav"
            :key="index"
          >
            <CourseItem
              @faBtn="faBtn(item.courseId)"
              imgw="280"
              imgh="175"
              :coverImg="item.coverImg"
              :title="item.title"
              :doctoName="item.teacher"
              :introduce="item.label"
              :price="item.salePrice"
              :original="item.markPrice"
              :playNum="item.realPlayCount"
              :free="item.free == 1 ? true : false"
            ></CourseItem>
          </div>
        </div>
        <div class="text-c">
          <Page
            @on-change="change"
            :total="total"
            :page-size="per_page"
            :current="current_page"
            prev-text="上一页"
            next-text="下一页"
          />
          <slot>
            <span class="ml20"
              >共有 <span class="co36">{{ total }}</span> 条数据</span
            >
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../components/Icon.vue";
import CourseItem from "../components/CourseItem.vue";
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    Icon,
    CourseItem,
  },
  data() {
    return {
      total: 0, //数据总数
      order_page: 1, //页码
      current_page: 1, //当前页码
      per_page: 0, //每页显示条数
      last_page: 0, //最后一页
      menuNav: [],
      courseList: [],
      curMenu: 0,
      filList: [
        { name: "全部" },
        { name: "免费" },
        { name: "最新" },
        { name: "热门" },
      ],
      curFil: 0,
    };
  },
  created() {
    this.getCourseList();
  },
  methods: {
    faBtn(courseId) {
      console.log("courseId", courseId);
      this.$router.push({
        path: "/CourseDetail",
        query: { courseId: courseId },
      });
    },
    //分页
    change(v) {
      this.order_page = v;
      this.getList();
    },
    // 二级分类
    curFils(inx) {
      console.log(inx);
      if (inx == 1) {
        $.ajax({
          url: "https://yzx.yeswedo.com.cn/api/index/course_list",
          type: "get",
          data: "free=" + 1,
          dataType: "json",
        })
          .then((res) => {
            console.log("res", res);
            this.menuNav = res.data.data;
            this.total = res.data.total;
            this.current_page = res.data.current_page;
            this.per_page = res.data.per_page;
            this.last_page = res.data.last_page;
          })
          .fail(function (err) {});
      } else if (inx == 2) {
        $.ajax({
          url: "https://yzx.yeswedo.com.cn/api/index/course_list",
          type: "get",
          data: "onnew=" + 1,
          dataType: "json",
        })
          .then((res) => {
            console.log("res", res);
            this.menuNav = res.data.data;
            this.total = res.data.total;
            this.current_page = res.data.current_page;
            this.per_page = res.data.per_page;
            this.last_page = res.data.last_page;
          })
          .fail(function (err) {});
      } else if (inx == 3) {
        $.ajax({
          url: "https://yzx.yeswedo.com.cn/api/index/course_list",
          type: "get",
          data: "hot=" + 0,
          dataType: "json",
        })
          .then((res) => {
            console.log("res", res);
            this.menuNav = res.data.data;
            this.total = res.data.total;
            this.current_page = res.data.current_page;
            this.per_page = res.data.per_page;
            this.last_page = res.data.last_page;
          })
          .fail(function (err) {});
      } else {
        $.ajax({
          url: "https://yzx.yeswedo.com.cn/api/index/course_list",
          type: "get",
          dataType: "json",
        })
          .then((res) => {
            console.log("res", res);
            this.menuNav = res.data.data;
            this.total = res.data.total;
            this.current_page = res.data.current_page;
            this.per_page = res.data.per_page;
            this.last_page = res.data.last_page;
          })
          .fail(function (err) {});
      }
    },
    // 导航栏
    curMenus(typId) {
      $.ajax({
        url: "https://yzx.yeswedo.com.cn/api/index/course_list",
        type: "get",
        data: "firstTypeId=" + typId,
        dataType: "json",
      })
        .then((res) => {
          this.menuNav = res.data.data;
          this.total = res.data.total;
          this.current_page = res.data.current_page;
          this.per_page = res.data.per_page;
          this.last_page = res.data.last_page;
        })
        .fail(function (err) {});
    },

    getCourseList() {
      $.ajax({
        url: "https://yzx.yeswedo.com.cn/api/index/course_cate",
        type: "get",
        dataType: "json",
      })
        .then((res) => {
          this.courseList = res.data;
        })
        .fail(function (err) {});
    },
    // 一级分类列表
    getList() {
      $.ajax({
        url: "https://yzx.yeswedo.com.cn/api/index/course_list",
        type: "get",
        data: { firstTypeId: this.queryId, pageNum: this.order_page },
        dataType: "json",
      })
        .then((res) => {
          this.menuNav = res.data.data;
          this.total = res.data.total;
          this.current_page = res.data.current_page;
          this.per_page = res.data.per_page;
          this.last_page = res.data.last_page;
        })
        .fail(function (err) {});
    },
  },
  mounted() {
    this.queryId = this.$route.query.firstTypeId;
    this.getList();
  },
};
</script>

<style scoped lang="scss">
.main {
  background: #f8f8f8;
}
.m-head {
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(204, 204, 204, 0.2);
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  .mh-con {
    & > div {
      &:not(.mhc-ac):hover {
        color: #ffa54b;
      }
      &:not(:first-child) {
        margin-left: 14px;
      }
    }
    .mhc-ac {
      background: #ffa54b;
      box-shadow: 0px 2px 4px 0px rgba(201, 101, 0, 0.2);
      border-radius: 16px;
      color: #ffffff;
    }
  }
}
.m-top {
  height: 50px;
  background: #ffffff;
  border-radius: 6px;
}
.m-con {
  .mc-li {
    background-color: #fff;
  }
}
</style>
